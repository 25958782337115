import Vuex from 'vuex';
import importacaoPecas from './importacaoPecas';
import login from './login';
import dialogostep from './dialogostep';
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
    storage: window.sessionStorage,
    modules: ['importacaoPecas','login']
  })

export default new Vuex.Store({
    modules: {
        importacaoPecas,
        login,
        dialogostep
    },
    plugins: [vuexLocal.plugin]
})
