<template>
  <div>
    <v-app-bar color="lighten-3" dense clipped-left app elevation="1">
      <v-app-bar-nav-icon @click.stop="miniMenu"></v-app-bar-nav-icon>
      <v-img
        src="@/assets/imagens/logo-executa-rd.png"
        height="100%"
        max-width="180"
        contain
      ></v-img>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-avatar color="primary" size="36" v-bind="attrs" v-on="on">
            <span
              class="white--text headline"
              style="font-size: 15px !important"
              >{{User.nome.substr(0, 2).toUpperCase() }}</span
            >
          </v-avatar>
        </template>
        <v-card>
          <v-list dense nav>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ User.nome }}</v-list-item-title>
                <v-list-item-subtitle>{{ User.email }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>

          <v-divider></v-divider>
          <v-list dense nav>
                <v-list dense nav>
                        <v-list-item link @click="rotaPerfil">
                            <v-list-item-icon>
                                <v-icon>mdi-account-cog</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Perfil do Usuário</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
            <v-list-item link @click="Sair()">
              <v-list-item-icon>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Sair da sessão</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer clipped app permanent :expand-on-hover="mini">
      <v-list nav dense>
        <v-list-item link to="/Gerenciamentodepecas">
          <v-list-item-icon>
            <v-icon>note_add</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Cadastro</v-list-item-title>
        </v-list-item>
        
        <v-list-item link to="/GerenciamentoDeCampanha">
          <v-list-item-icon>
            <v-icon>local_shipping</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Distribuição</v-list-item-title>
        </v-list-item>

     <v-list-group prepend-icon="mdi-chart-pie">
              <template v-slot:activator>
                <v-list-item-content >                
                  <v-list-item-title>Relatórios</v-list-item-title>
                </v-list-item-content>
              </template>

          <v-list-item sub-group link to="/ControleDePositivacao">
          <v-list-item-icon>
            <v-icon>gamepad</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Controle de Positivação</v-list-item-title>
        </v-list-item>

          <v-list-item sub-group link to="/Positivacao">
          <v-list-item-icon>
            <v-icon>thumb_up_alt</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Positivação</v-list-item-title>
        </v-list-item>

            <v-list-item link to="/Justificativas">
          <v-list-item-icon>
            <v-icon>mdi-chart-arc</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Justificativas</v-list-item-title>
        </v-list-item>

            <v-list-item link to="/Dashboard">
          <v-list-item-icon>
            <v-icon>mdi-chart-areaspline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Dashboard</v-list-item-title>
        </v-list-item>
     </v-list-group>

     <v-list-group prepend-icon="mdi-cellphone">
      <template v-slot:activator>
        <v-list-item-content >
          <v-list-item-title>Aplicativo</v-list-item-title>
        </v-list-item-content>
      </template>
       <v-list-item link to="/Mensagem">
          <v-list-item-icon>
            <v-icon>tap_and_play</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Mensagens-Importante</v-list-item-title>
        </v-list-item>

        <v-list-item link to="/Aviso">
          <v-list-item-icon>
            <v-icon>warning</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Push</v-list-item-title>
        </v-list-item>

       <v-list-item link to="/Justificativa">
         <v-list-item-icon>
           <v-icon>mdi-cellphone-check</v-icon>
         </v-list-item-icon>
         <v-list-item-title>Justificativas</v-list-item-title>
       </v-list-item>
     </v-list-group>

    </v-list>


       <template v-slot:append >
                <v-img class="img_logao_nav" src="@/assets/imagens/executa-mkt-logo.png">
                </v-img>
            
            </template>
    </v-navigation-drawer>

    <v-main class="fundo">
      <router-view />
    </v-main>

    <v-footer app class="justify-center">
      IPDV © {{ new Date().getFullYear() }} | Todos os direitos reservados |
      Política de Privacidade
    </v-footer>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      mini: null,
    };
  },
  computed: {
    User() {
      localStorage.setItem('userid',this.$store.state.login.user?.id) // resgata o userid para localStorage
      
     
      return this.$store.state.login.user || {
        nome: "Usuário",
        email: ""
      };
    },
  },
  methods: {
      rotaPerfil () {
            if(this.$route.name != 'perfil'){
                this.$router.push({name: "perfil"})
            }
        },
    miniMenu() {
      localStorage.setItem("miniMenu", !this.mini);
      this.mini = !this.mini;
    },
    async Sair() {
      const res = (await axios.post(`${this.$SERVER_URL}adm/logout`, {} ,{
         headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods":
              "GET, POST, PATCH, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers":
              "Origin, Content-Type, X-Auth-Token",
            "Content-Type": "application/json"
          },
          withCredentials: true
        })
      ).data;
        console.log(res)
      this.$cookies.remove("mkt-token");
      console.log(this.$cookies.get("mkt-token"));
      this.$store.commit("login/login", {
        login: false,
      });
      localStorage.clear();
      sessionStorage.clear();
      this.$router.push({
        path: "/Login",
      });
    },
  },
};
</script>

<style >
/* 
   estilos
    │ 
    └─────> data table  
    │ 
    └─────> botoes  
    │ 
    └─────> highcharts    
*/

/* borda menu */

.v-navigation-drawer__border {
  width: 2px !important;
}

/* fundo do main */

.fundo {
  background: #f2f7f7;
}

/* *********************** data table inicio *********************** */

.v-data-table-header {
  background-color: #6ababa;
}
.data-table-2 .v-data-table-header {
  background-color: #fff !important;
}

.v-data-table-header__icon {
  color: #fff !important;
}

.v-data-table-header .v-icon {
  color: #fff !important;
}

.v-data-table .v-data-table__wrapper table {
  width: 99% !important;
}

th {
  color: #fff !important;

  white-space: nowrap;
  padding: 6px !important;
}

.data-table-2 th {
  color: var(--v-primary-base) !important;
}

td {
  white-space: nowrap;
  padding: 6px !important;
}

.sortable span {
  padding-left: 18px;
}

.v-data-table-header__sort-badge {
  color: #fff !important;
  padding-left: 0 !important;
}

/* *********************** data table fim *********************** */

/* *********************** botoes inicio *********************** */

.v-btn.v-size--default {
  font-size: 0.75rem !important;
}

.v-btn {
  letter-spacing: normal;
  font-weight: 400;
}

/* *********************** botoes fim *********************** */

/* *********************** highcharts inicio *********************** */

.highcharts-root {
  font-family: "Roboto", sans-serif !important;
}


/* *********************** highcharts fim *********************** */


/* *********************** v-navigation inicio *********************** */


/* *********************** v-navigation fim *********************** */
</style>

