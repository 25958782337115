import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import 'vuetify/src/styles/main.sass'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'md',
    },

    theme: {
        options: { customProperties: true },
        themes: {
            light: {
                primary: '#6ababa',              
            },
        },
    },
});
