export default {
    namespaced: true,
    state: () => ({
        login:false,
        user: {
            id: '',
            nome: '',
            email: ''
        }
    }),
    mutations: {
        login(state,{login,user}){
            state.login = login;
            state.user = user
        },
    }
}