import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';

import Notifications from 'vue-notification'
import './plugins/vuex';
import store from './store'
import router from './router'
import moment from 'moment'
// import axios from 'axios';
import { arquivoProtegido, assinarUrl } from './plugins/imagemProtegida';
import VueCookies from 'vue-cookies'

Vue.use(VueCookies)
Vue.$cookies.config('1d')

// var authHeader = 'Bearer teste';
// axios.defaults.headers.common['Authorization'] = authHeader;

import pptxgen from "pptxgenjs" //Exportar ppt 
Vue.prototype.$arquivoProtegido = arquivoProtegido
Vue.prototype.$assinarUrl = assinarUrl



Vue.prototype.$pptxgen =  pptxgen
Vue.prototype.$moment =  moment

Vue.config.productionTip = false
Vue.use(vuetify);
Vue.use(Notifications)




switch (window.location.hostname) {
	case 'localhost':
		Vue.prototype.$SERVER_URL  = 'http://localhost:3016/';
		break;
	case 'mkt.rdtrade.com.br':
		Vue.prototype.$SERVER_URL  = 'https://api-mkt.rdtrade.com.br/'; // servidor de producao
		break;
	case 'raiamktfront.readpdv.com.br':
		Vue.prototype.$SERVER_URL  = 'http://raiatradenode.readpdv.com.br/';  // servidor de homologação
		break;
	case 'rdteste.readpdv.com.br':
		Vue.prototype.$SERVER_URL  = 'http://3.220.252.99:3021/'; // servidor de testes
		break;
	case 'raianovomkt.rdtrade.com.br':
			Vue.prototype.$SERVER_URL  = 'https://api-novamkt.rdtrade.com.br/'; // servidor de testes
			break;
	default:
    Vue.prototype.$SERVER_URL  = 'http://localhost:3016/';
}


Vue.filter('moment', function (date) { return moment(date).format('DD/MM/YYYY'); })

new Vue({
  router,
  vuetify,
  store,
  
  render: h => h(App)
}).$mount('#app')
