import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'

import Main from '../components/Main'
import Login from '../views/Login'

Vue.use(VueRouter)

const routes = [
  {
    path: '/Login',
    name: 'Login',
    component: Login
  },
  {
    path: '/assinarUrl',
    name: 'assinarUrl',
    component: () => import(/* webpackChunkName: "Mensagem" */ '../views/assinarUrl.vue')
  },
  {
    path: '/',
    component: Main,
    children: [
      {
        path: '/Home',
        name: 'Home',
        component: () => import(/* webpackChunkName: "GerenciamentoDePecas" */ '../views/Home.vue')
      },
      {
        path: '/GerenciamentoDePecas',
        name: 'Gerenciamento De Peças',
        component: () => import(/* webpackChunkName: "GerenciamentoDePecas" */ '../views/GerenciamentoDePecas')
      },
      {
        path: '/GerenciamentoDeCampanha',
        name: 'Gerenciamento De Campanha',
        component: () => import(/* webpackChunkName: "GerenciamentoDeCampanha" */ '../views/GerenciamentoCampanha.vue')
      },
      {
        path: '/ImportacaoDePeca',
        name: 'Importação de Peças',
        component: () => import(/* webpackChunkName: "ImportacaoDePeca" */ '../views/ImportacaoDePeca')
      },
      {
        path: '/ValidacaoImportacaoDePeca',
        name: 'Validação de Importação de Peças',
        component: () => import(/* webpackChunkName: "ValidacaoImportacaoDePeca" */ '../views/ValidacaoImportacaoDePeca')
      },
      {
        path: '/ControleDePositivacao',
        name: 'Controle de Positivação',                                                  
        component: () => import(/* webpackChunkName: "ControleDePositivacao" */ '../views/ControleDePositivacao')
      },
      {
        path: '/Justificativas',
        name: 'Justificativas',
        component: () => import(/* webpackChunkName: "Justificativas" */ '../views/Justificativas')
      },
      {
        path: '/Dashboard',
        name: 'Dashboard',
        component: () => import(/* webpackChunkName: "Dashboard" */ '../views/Dashboard')
      },
      {
        path: '/Positivacao',
        name: 'Positivação',
        component: () => import(/* webpackChunkName: "Positivacao" */ '../views/Positivacoes')
      },
      {
        path: '/perfil',
        name: 'perfil',
        component: () => import(/* webpackChunkName: "Perfil" */ '../components/Perfil')
      },
      {
        path: '/Mensagem',
        name: 'Mensagem',
        component: () => import(/* webpackChunkName: "Mensagem" */ '../views/Mensagem/Mensagem.vue')
      },
      {
        path: '/Aviso',
        name: 'Avisos Aplicativo',
        component: () => import(/* webpackChunkName: "Mensagem" */ '../views/AvisoApp/AvisoApp.vue')
      },
        {
            path: '/Justificativa',
            name: 'Justificativas Aplicativo',
            component: () => import(/* webpackChunkName: "Mensagem" */ '../views/JustificativaApp/JustificativaApp.vue')
        }

    ]
  },



]

const router = new VueRouter({
 // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  console.log('to', to)
  if(to.path == "/assinarUrl") {
    console.log('asasas')
    return next()
  }
  if(to.name != "Login" && !store.state.login.login) next({ name: 'Login' })
  else next()

})

export default router
