
export default {
    namespaced: true,
    state: () => ({
       step: 1

    }),
    mutations: {
        MudarState(state,{step}){
         
            state.step = step;
        },
    
    }
}