export function arquivoProtegido(url) {
  if (!url) return null;
  if (url.startsWith("data:image")) return url;
  return `${this.$SERVER_URL}s3/assinarUrl?url=${url}`;
}
export const assinarUrl = (url) => {
  if (url.startsWith("data:image")) return url;

  const base = window.location.origin;
  return `${base}#/assinarUrl?url=${url}`;
};
