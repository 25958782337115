<template>
  <v-app style="background: #e8e8e8">
    <v-main class="bg align-center">
      <v-container fluid>
        <v-row justify="end">
          <v-col sm="12" md="8" lg="5" xl="5">
            <v-card class="mx-10 pa-8">
              <v-img
                src="@/assets/imagens/executa-rd-fundo-branco.png"
                height="150"
                contain
              ></v-img>

              <v-form ref="form" lazy-validation @submit.prevent="Login">
                <v-text-field
                  class="mt-15"
                  solo
                  label=""
                  prepend-inner-icon="mdi-account"
                  v-model="email"
                  :rules="rules"
                  hide-details="auto"
                ></v-text-field>

                <v-text-field
                  class="mt-3"
                  solo
                  label=""
                  :append-icon="show1 ? 'mdi-lock' : 'mdi-eye-off'"
                  v-model="senha"
                  :rules="rules2"
                  required
                  hide-details="auto"
                  @click:append="show1 = !show1"
                  :type="show1 ? 'text' : 'password'"
                ></v-text-field>

                <v-btn class="mt-3" color="primary" type="submit">
                  Entrar
                </v-btn>
              </v-form>

             <v-snackbar v-model="snackbar">
              Email ou senha errado
              <template v-slot:action="{ attrs }">
                <v-btn color="red" text v-bind="attrs" @click="snackbar = false">Close</v-btn>
              </template>
            </v-snackbar>
            
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>


<script>
import axios from "axios";


export default {
  data: () => ({
    rules: [value => !!value || "Campo obrigatorio"],
    rules2: [value => !!value || "Campo obrigatorio"],
    email: "",
    senha: "",
    show1: false,
    snackbar: false
  }),

  methods: {
    async Login() {
    

       const res = (await axios.post(`${this.$SERVER_URL}adm/login`, {email:this.email,senha:this.senha},{
         headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods":
              "GET, POST, PATCH, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers":
              "Origin, Content-Type, X-Auth-Token",
            "Content-Type": "application/json"
          },
          withCredentials: true
        })
      ).data;
       const user = res.user
     
      if(!res || !res.token || res.token === "") this.snackbar = true;
      else {
        this.$store.commit("login/login", {
          login: true,
          user
          
        });
       this.$cookies.set("mkt-token", res.token, null);      
        this.$router.push({
          path: "/Home"
        });
      }
    }
  }
};
</script>

<style scoped>
.bg {
  background-image: url("../assets/imagens/bg.jpg");
  background-size: auto 100%;
}
</style>