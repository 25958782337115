export default {
    namespaced: true,
    state: () => ({
        xls:null,
        pecasJaCadastradas: [],
        imagemPositivacao: [],
        imagem3D: [],

    }),
    mutations: {
        pecasJaCadastradas(state,{pecas}){
            state.pecasJaCadastradas = pecas;
        },
        selectFiles (state, {xls,imagem3D,imagemPositivacao}) {
            state.xls = xls;
            state.imagem3D = imagem3D;
            state.imagemPositivacao = imagemPositivacao;
           
        },
        reset(state){
          
            state.xls = null;
            state.imagemPositivacao = [];
            state.imagem3D = [];
        }
    }
}